@import "../../theme/breakpoints";

.page-wrapper {
  min-height: 100vh;
  background: var(--background-color-medium);
  display: flex;
  flex-direction: column;
}

.page-wrapper__header {
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.page-wrapper__content {
  display: grid;
  grid-area: content;
  max-width: 100%;
  flex-grow: 1;

  @media screen and (max-width: $tablet-portrait-max) {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: minmax(0, 1fr);

    .support {
      display: none;
    }
  }
  @media print and (orientation: portrait) {
    display: block;
  }
  @media print {
    min-height: 50vh;
  }
}

.page-wrapper__footer {
  grid-area: footer;
}

@media print {
  .page-wrapper__footer {
    display: none;
  }
  .page-wrapper__header {
    position: static;
  }
}
