*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font: var(--font-body);
  color: var(--text-color);
}

body {
  margin: 0;
  max-width: 100%;
  overscroll-behavior: none; // Prevent bouncing when scrolling past limits
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  font-family: inherit;
  color: inherit;
  cursor: pointer;
}

:disabled {
  pointer-events: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a,
a:visited {
  color: inherit;
}

a:focus,
button:focus,
:-moz-focusring {
  outline: none;
  box-shadow: none;
}

a:focus-visible,
button:focus-visible {
  outline: var(--focus-outline);
  box-shadow: none;
}
