@import "../../../../shared/theme/breakpoints";

.attachment-form {
  width: 64.25rem;
  height: 45rem;
  border-radius: 40px;
  margin-top: auto;
  margin-left: auto;
  margin-bottom: auto;
  margin-right: auto;
  font-family: "Sage-Text";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  background-color: white;
  &__item {
    display: flex;
    justify-content: space-evenly;
  }
  &__logo {
    width: 6.34rem;
    margin-top: 2.5rem;
    height: 3rem;
  }
  &__header {
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 1.5rem;
    position: -webkit-sticky; /* Safari & IE */
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    border-radius: 40px;
  }
  &__title {
    margin-top: 0.6rem;
    font-family: "Sage-Headline";
    font-style: normal;
    font-weight: 900;
    line-height: 2.5rem;
    font-size: 1.87rem;
  }

  &__item-label {
    line-height: var(--line-height-body-l);
  }

  &__button {
    margin: auto;
  }
  &__middle {
    margin: auto;
  }

  &__input {
    width: 28.68rem;
    border-color: #668592;
  }
  &__message {
    margin: 2.5rem;
  }

  &__button {
    width: 59.6rem;
    margin-top: 2.5rem;
    padding-left: 24.5rem;
    border-radius: 40px;
  }

  &__input-code {
    width: 59.5rem;
    margin: auto;
  }
  .center-radios {
    margin-top: 3px;
  }

  hr {
    border-top: #ccd6db;
  }
  .error-message-form {
    margin: 0.5em 0em 0.5em 0em;
    color: var(--error-color);
    font-size: 0.8rem;
    font-weight: 500;
  }
  .disabled {
    background-color: #d3d3d3;
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .attachment-form {
    width: auto;
    height: max-content;
    &__item {
      display: block;
      margin: auto;
    }
    &__input-code {
      width: auto;
    }
    &__input {
      width: auto;
      border-color: #668592;
      margin: auto;
    }
    &__button {
      width: auto;
      margin-top: 2.5rem;
      padding-left: 3rem;
    }
    &__middle {
      padding: 1.5rem;
    }
  }
}

@media screen and (max-width: $mobile-max) {
  .attachment-form {
    width: max-content;
    height: max-content;
    &__item {
      display: block;
      margin: auto;
    }
    &__input-code {
      width: auto;
    }
    &__input {
      width: auto;
      border-color: #668592;
      margin: auto;
    }
    &__button {
      width: auto;
      margin-top: 2.5rem;
      padding-left: 3rem;
    }
    &__middle {
      padding: 1.5rem;
    }
  }
}
.rattachement-message1 {
  font-size: 14px;
  font-family: "Sage-Text";
}
.rattachement-message2 {
  font-weight: bold;
  font-family: "Sage-Text";
}
.radio-code-margin {
  margin-left: 2.3rem;
}
.bottom-margin-radio {
  margin-bottom: 2.5rem;
}
