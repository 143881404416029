@import "../../../theme/breakpoints";

.main-footer {
  padding: var(--spacing-6x);
  margin-top: auto;
  padding-top: 15px;
  display: flex;
  color: #606060;
  font-family: "Adelle-Sans-Sage";
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}

.main-footer__links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media screen and (max-width: $tablet-portrait-max) {
    margin: 0 calc(var(--spacing-4x) * -1);
    text-align: center;
  }

  @media screen and (max-width: $mobile-max) {
    flex-direction: column;
  }
}

.main-footer__link {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 calc(var(--spacing-1x) * 12);

  @media screen and (max-width: $tablet-landscape-max) {
    margin: 0 var(--spacing-8x);
  }

  @media screen and (max-width: $tablet-portrait-max) {
    flex-direction: column;
    margin: 0 var(--spacing-4x);
    text-align: center;
  }

  @media screen and (max-width: $mobile-max) {
    margin: var(--spacing-4x) 0;
  }
}

.main-footer__logo,
.main-footer__icon {
  margin-right: var(--spacing-5x);

  @media screen and (max-width: $tablet-portrait-max) {
    margin: 0 0 var(--spacing-4x);
  }
}

.main-footer__logo {
  width: 115px;
}

.main-footer__icon {
  height: 60px;
}

.main-footer__container {
  position: fixed;
  bottom: 0;
  width: 100%;
}
