// Phone inputs
.PhoneInputCountry {
  padding-left: 2px;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: none;
  outline: var(--focus-outline);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  color: inherit;
}
