@import "../../../../shared/theme/breakpoints";

.server-error {
  max-height: 41rem;
  position: relative;
  top: 0;
  left: 0;
  margin-top: auto;
  margin-left: auto;
  margin-bottom: auto;
  margin-right: auto;
  background-color: transparent;
  white-space: pre-line;
  display: flex;
  width: 63rem;
  font-family: "Sage-Text";

  &__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
    padding-bottom: 2rem;
    font-family: "Sage-Headline";
  }
  &__text {
    padding-left: 3rem;
  }
  &__message {
    font-size: 20px;
    line-height: 32px;
    width: 35rem;
    font-style: normal;
    font-weight: normal;
  }
  &__link {
    color: #198e59;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    padding-top: 4.5rem;
  }
  &__link_sign_Doc {
    color: white !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    padding-top: 1.5rem;
  }
  &__image img {
    max-width: inherit;
  }
  &__error {
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 51.2px;
    color: #000000;
    font-family: "Sage-Text";
  }
  &__link_due_diligence {
    color: #198e59 !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    padding-top: 1rem;
  }
  &__message_attention {
    font-size: 12px;
    line-height: 21px;
    width: 35rem;
    font-style: normal;
    font-weight: normal;
  }
  &__subtitle_attention {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    padding-bottom: 0.25rem;
  }
  &__countries_attention {
    font-size: 15px;
    line-height: 32px;
    width: 35rem;
    font-style: italic;
    font-weight: normal;
    padding-bottom: 0.25rem;
  }
  &__title_attention {
    font-family: "Sage-Headline" !important;
    font-size: 32px;
    line-height: 65px;
    padding-bottom: 0.25rem;
    padding-top: 1rem;
  }
}

@media screen and (max-width: $tablet-portrait-max) {
  .server-error {
    flex-direction: column;
    width: fit-content;
    padding: 1.5rem;

    &__image {
      margin: auto;
    }
  }
}
@media screen and (max-width: $tablet-landscape-max) {
  .server-error {
    width: fit-content;
    padding: 1rem;

    &__image img {
      width: 25rem;
    }
  }
}

@media screen and (max-width: $mobile-max) {
  .server-error {
    flex-direction: column;
    width: fit-content;
    padding: 1.5rem;

    &__image {
      width: fit-content;
    }
    &__image img {
      width: 15rem;
    }
    &__message {
      width: 20rem;
    }
  }
}
a {
  .icon {
    svg {
      padding-top: 6px !important;
    }
  }
}
