.button {
  display: flex;
  align-items: center;
  height: var(--height, auto);
  padding: 0 var(--padding, 4px);
  border: 1px solid var(--border-color, transparent);
  background: var(--background-color, transparent);
  color: var(--text-color, inherit);
  font-family: inherit;
  font-size: 1rem;
  border-radius: 40px;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
  }
}

// --------------------------
// Button sizes
//   large: 48px
//   medium: 40px
// --------------------------
.button--large {
  --height: 3rem;
  --padding: 32px;
}

.button--medium {
  --height: 2.5rem;
  --padding: 24px;
}

// --------------------------
// Button styles
//   primary: black
//   secondary: white with black border
//   tertiary: no background, no border
// --------------------------
.button--primary {
  --background-color: #198e59;
  --border-color: transparent;
  --text-color: var(--text-color-clear);

  &:hover,
  &:focus {
    --background-color: var(--black-75);
  }

  &[disabled] {
    --background-color: var(--disabled-color);
  }
}

.button--secondary {
  --background-color: var(--white);
  --border-color: var(--cta-color);
  --text-color: var(--cta-color);

  &:hover,
  &:focus {
    --background-color: var(--cta-color);
    --border-color: transparent;
    --text-color: var(--text-color-clear);
  }

  &[disabled] {
    --background-color: var(--white);
    --border-color: var(--disabled-color);
    --text-color: var(--disabled-color);
  }
}

.button--tertiary {
  --background-color: transparent;
  --border-color: transparent;
  --text-color: inherit;

  text-decoration: underline;

  &[disabled] {
    --text-color: var(--disabled-color);
  }
}
