.blocked-tiers {
  font-family: "Sage-Text";
  font-style: normal;
  text-align: center;
  margin: auto;
  &__message {
    width: 65%;
    margin: auto;
    font-size: 24px;
    color: black;
    margin-top: 5.2rem;
    font-weight: bold;
    font-family: "Sage-Headline";
    line-height: 38px;
  }

  &__message-timer {
    margin-top: 1.5rem;
    font-size: 20px;
    line-height: 32px;
  }

  &__timer {
    font-family: "Sage-Headline";
    color: #00a159;
    font-weight: 900;
    font-size: 90px;
    line-height: 120%;
  }
}
