// --------------------------
// Adelle Sans Sage
// normal: bold, regular, light, extra-light
// --------------------------

@font-face {
  font-family: "Adelle-Sans-Sage";
  font-style: normal;
  font-weight: bold;
  src: url("/shared/assets/fonts/adelle-sans/AdelleSansSage-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Adelle-Sans-Sage";
  font-style: normal;
  font-weight: normal;
  src: url("/shared/assets/fonts/adelle-sans/AdelleSansSage.ttf") format("truetype");
}

@font-face {
  font-family: "Adelle-Sans-Sage";
  font-style: normal;
  font-weight: 300;
  src: url("/shared/assets/fonts/adelle-sans/AdelleSansSage-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Adelle-Sans-Sage";
  font-style: normal;
  font-weight: 200;
  src: url("/shared/assets/fonts/adelle-sans/AdelleSansSage-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Sage-Text";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/sage-text/Sage_Text-Regular.woff") format("truetype");
}

@font-face {
  font-family: "Sage-Headline";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/sage-headline/Sage_Headline-Black.woff") format("truetype");
}

@font-face {
  font-family: "Sage-Ui";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/sage-ui/Sage_UI-Regular.woff") format("truetype");
}
