@import "../../../../shared/theme/breakpoints";
.tiers-select {
  width: 64.25rem;
  border-radius: 40px;
  font-family: "Sage-Text";
  height: 40.6rem;
  position: relative;
  top: 0;
  left: 0;
  margin-top: auto;
  margin-left: auto;
  margin-bottom: auto;
  margin-right: auto;
  background-color: white;
  font-weight: bold;
  overflow: auto;

  &__title {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 2.5rem;
    height: 21px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25.2px;
    text-align: center;
  }
  &__tiers-list {
    width: 75%;
    margin: auto;
  }
  &__tiers-list-item {
    box-shadow: inset 0px 0px 0px 1px #ccd6db;
  }
  &__tiers-list-item:hover {
    background-color: #ccd6db;
  }
}
@media screen and (max-width: $tablet-portrait-max) {
  .tiers-select {
    width: fit-content;
    padding: 1rem;
  }
}

@media screen and (max-width: $mobile-max) {
  .tiers-select {
    width: fit-content;
    padding: 1rem;

    margin: auto;
  }
}
