.authentication-form {
  width: 26.5rem;
  height: 22.4rem;
  position: relative;
  top: 0;
  left: 0;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  border-radius: 40px;
  margin-bottom: auto;
  background-color: white;
  font-weight: bold;

  &__logo {
    width: 6.34rem;
    margin-top: 2.5rem;
  }
  &__header {
    text-align: center;
  }
  &__title {
    font-family: "Adelle-Sans-Sage", Lato, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 900;
    line-height: 2.5rem;
    font-size: 1.87rem;
  }

  &__item-label {
    line-height: var(--line-height-body-l);
  }

  &__button {
    margin: auto;
    border-radius: 40px;
  }

  &__item {
    padding: 2.3rem;
  }
  &__item-label {
    text-align: start;
  }
}
