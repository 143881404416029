.tiers-element {
  display: flex;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  height: 3.3rem;
  font-family: "Sage-Text";
  cursor: pointer;

  &__name {
    font-size: 14px;
    line-height: 21px;
  }
  &__id {
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }
}

.tiers-sub-element {
  display: flex;
  margin-left: 5rem;
  margin-right: 2.5rem;
  height: 3.3rem;
  box-shadow: inset 0px -1px 0px #ccd6db;
  cursor: pointer;

  &__name {
    font-size: 16px;
    line-height: 24px;
  }
  &__id {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #7f7f7f;
  }
}

.tiers-sub-element:hover {
  background-color: #e2f5e2;
}
