:root {
  // --------------------------
  // DESIGN TOKENS - colors
  // --------------------------
  // Palette
  --sbc-green: rgba(0, 225, 75, 1);
  --sbc-green-15: rgba(0, 225, 75, 0.15);
  --sbc-green-dark: #008200;
  --black-90: rgba(0, 0, 0, 0.9);
  --black-75: rgba(0, 0, 0, 0.75);
  --black-65: rgba(0, 0, 0, 0.65);
  --black-30: rgba(0, 0, 0, 0.3);
  --black-5: rgba(242, 242, 242, 1);
  --white: #fff;
  --gold: rgba(255, 181, 0, 1);
  --red: rgba(199, 56, 79, 1);

  // Functional colors
  --sbc-color: var(--sbc-green);
  --cta-color: var(--sbc-green-dark);
  --text-color: var(--black-90);
  --text-color-clear: var(--white);
  --background-color-dark: var(--black-90);
  --background-color-medium-dark: var(--black-30);
  --background-color-medium: var(--black-5);
  --background-color-light: var(--white);
  --outline-color: var(--gold);
  --disabled-color: var(--black-30);
  --error-color: var(--red);

  // --------------------------
  // DESIGN TOKENS - Typography
  // --------------------------
  // Font families
  --adelle-sans-sage: "Adelle-Sans-Sage", sans-serif;

  // Font sizes - headings
  --font-size-h1: 2.25rem; // 36px
  --font-size-h2: 2rem; // 32px
  --font-size-h3: 1.5rem; // 24px
  --font-size-h4: 1.25rem; // 20px
  --font-size-h5: 1.125rem; // 18px
  --font-size-h6: 1.5rem; // 24px

  // Font sizes - body
  --font-size-body-l: 1.125rem; // 18px
  --font-size-body: 1rem; // 16px
  --font-size-body-s: 0.875rem; // 14px

  // Weight

  --font-weight-body: 400;

  // Line heights - headings
  --line-height-h1: 2.875rem; // 46px
  --line-height-h2: 2.625rem; // 42px
  --line-height-h3: 2.125rem; // 34px
  --line-height-h4: 1.875rem; // 30px
  --line-height-h5: 1.75rem; // 28px
  --line-height-h6: 2.125rem; // 34px

  // Line heights - body
  --line-height-body-l: 1.75rem; // 28px
  --line-height-body: 1.375rem; // 22px

  // Font shorthands - headings
  --font-h1: bold var(--font-size-h1) / var(--line-height-h1) var(--adelle-sans-sage);
  --font-h2: bold var(--font-size-h2) / var(--line-height-h2) var(--adelle-sans-sage);
  --font-h3: bold var(--font-size-h3) / var(--line-height-h3) var(--adelle-sans-sage);
  --font-h4: bold var(--font-size-h4) / var(--line-height-h4) var(--adelle-sans-sage);
  --font-h5: bold var(--font-size-h5) / var(--line-height-h5) var(--adelle-sans-sage);
  --font-h6: 300 var(--font-size-h6) / var(--line-height-h5) var(--adelle-sans-sage);

  // Font shorthands - body
  --font-body-l: normal var(--font-size-body-l) / var(--line-height-body-l) var(--adelle-sans-sage);
  --font-body: normal var(--font-size-body) / var(--line-height-body) var(--adelle-sans-sage);

  // --------------------------
  // LAYOUT - spacings
  // --------------------------
  --spacing-base: 4px;
  --spacing-1x: calc(var(--spacing-base) * 1); // 4px
  --spacing-2x: calc(var(--spacing-base) * 2); // 8px
  --spacing-3x: calc(var(--spacing-base) * 3); // 12px
  --spacing-4x: calc(var(--spacing-base) * 4); // 16px
  --spacing-5x: calc(var(--spacing-base) * 5); // 20px
  --spacing-6x: calc(var(--spacing-base) * 6); // 24px
  --spacing-8x: calc(var(--spacing-base) * 8); // 32px
  --spacing-9x: calc(var(--spacing-base) * 9); // 36px
  --spacing-10x: calc(var(--spacing-base) * 10); // 40px

  // --------------------------
  // OTHER VARIABLES
  // --------------------------
  --focus-outline: 3px solid var(--outline-color);
}
