.carbon-form__input {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 4px;

  input,
  select {
    width: 100%;
    min-height: 40px;
    padding: 0 40px 0 12px;
    background: var(--white);
    border: 0.5px solid var(--background-color-medium-dark);
    color: var(--text-color);
    flex-grow: 1;
    font-size: var(--font-size-body);
    outline: none;

    &:focus {
      outline: var(--focus-outline);
    }

    &[disabled] {
      background: var(--color-border-light) none repeat scroll 0 0;
      border-color: var(--color-border-light);
      cursor: default;
    }
  }

  &.is-invalid input,
  &.is-invalid select {
    border-width: 3px;
    border-color: var(--error-color);
  }

  .carbon-form__input-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    width: 40px;
    pointer-events: none;
    img {
      margin: 30%;
    }
  }

  .carbon-form__valid-input-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    width: 40px;
    pointer-events: none;

    .icon {
      margin: auto;
      color: var(--color-business);
    }
  }
}
