@import "../../../../shared/theme/breakpoints";

.loading {
  &__message {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: $mobile-max) {
    width: 56vh;
  }
}
