@import "./breakpoints";

.details-form {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child:not(button) {
    margin-bottom: 0;
  }
}

.details-form__section {
  margin: var(--spacing-4x) 0;
  color: var(--black-65);

  &--highlight {
    color: var(--text-color);
  }
  &:hover {
    color: var(--black-9O);
  }
}

.details-form__section-row {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--spacing-2x) * -1) calc(var(--spacing-2x) * -1);

  // Row with inline radio buttons
  &--inline-radios {
    margin: calc(var(--spacing-1x) * -1) calc(var(--spacing-6x) * -1);
  }

  &__checkbox {
    flex: 0 0 100%;
    padding: var(--spacing-2x) var(--spacing-2x);
    align-items: unset;

    &-icon {
      flex: 0 0 auto;
      transform: translateY(-2px);
      margin-top: 5px;
    }
  }
}

.details-form__item {
  flex: 0 0 100%;
  padding: var(--spacing-2x) var(--spacing-2x);

  // form items that are not the only children of a row, take 50% of the row width
  &:not(:only-child) {
    flex: 0 0 50%;
  }

  @media screen and (max-width: $mobile-max) {
    &:not(:only-child) {
      flex: 0 0 100%;
    }
  }

  // Inline radio buttons
  .details-form__section-row--inline-radios & {
    flex: 0 0 auto;
    margin: var(--spacing-2x) var(--spacing-6x);
    padding: 0;
  }
}
.details-form__error-span {
  color: var(--error-color);
}
.details-form__address-section {
  display: grid;
  grid-template-columns: 2fr 1fr;
  @media screen and (max-width: $mobile-max) {
    display: block;
  }
}
.details-form__address-button {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5rem;
  cursor: pointer;
  margin-left: 1rem;
  text-decoration: underline;
}
.details-form__section-label,
.details-form__item-label {
  line-height: var(--line-height-body-l);

  &--small {
    font-size: var(--font-size-body-s);
  }
}

.details-form__description {
  line-height: var(--line-height-body);
  font-weight: var(--font-weight-body);
  color: var(--text-color);
  .company {
    display: inline-block;
    font-weight: 700;
    font-size: 1.2rem;
  }
  .siret {
    display: inline-block;
    font-size: 1.2rem;
  }
}

.details-form__text {
  margin: var(--spacing-4x) 0;
  color: black;
}

.details-form__submit {
  margin: var(--spacing-8x) 0 var(--spacing-2x);
}
// --------------------------
// Erreurs
// --------------------------
.details-form__item.is-invalid {
  .details-form__item-label {
    color: var(--error-color);
  }
  .carbon-form__input input {
    border-width: 1.5px;
    border-color: var(--error-color);
  }
}
.details-form__existing {
  width: 7.2rem;
  display: inline-block;
}

@media print {
  .checkout__header {
    display: none;
  }
  .checkout__stepper {
    display: none;
  }
}
